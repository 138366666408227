import { useEffect, useState } from "react";
import {
	Heading,
	Text,
	Divider,
	BreadcrumbItem,
	BreadcrumbLink,
	Breadcrumb,
	Stack,
	FormControl,
	InputGroup,
	Input,
	FormLabel,
	Textarea,
	Button,
	Flex,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	CardBody,
	CardHeader,
	Card,
	useToast,
	Switch,
	CheckboxGroup,
	Checkbox,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
	BackButton,
	MenuBar,
	PageContainer,
	ShiftCreator,
} from "../components/atoms";
import styled from "styled-components";
import { gql } from "@apollo/client";
import { DatePicker } from "../components/atoms";
import { Shift } from "../types";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const MUTATE_JOB_LISTING = gql`
	mutation jobListing(
		$id: String
		$jobName: String!
		$wage: Float!
		$jobRestrictions: String!
		$jobDescription: String!
		$shifts: [Shift!]!
		$startDate: Date!
		$isDraft: Boolean!
		$isContinuousRole: Boolean
		$isTrialShift: Boolean
	) {
		jobListing(
			id: $id
			jobName: $jobName
			wage: $wage
			jobRestrictions: $jobRestrictions
			jobDescription: $jobDescription
			shifts: $shifts
			startDate: $startDate
			isDraft: $isDraft
			isContinuousRole: $isContinuousRole
			isTrialShift: $isTrialShift
		)
	}
`;

export const ListJob = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const toast = useToast();
	const [createJobListing, { loading }] = useMutation(MUTATE_JOB_LISTING, {
		refetchQueries: ["ListJobs"],
	});

	useEffect(() => {
		if (state) {
			const {
				id,
				jobName,
				wage,
				jobRestrictions,
				jobDescription,
				shifts,
				startDate,
				isDraft,
				isContinuousRole,
				isTrialShift,
			}: any = state;

			const shiftsTemp = shifts.map((shift: any) => {
				return {
					...shift,
					start: shift.start,
					end: shift.end,
				};
			});

			setId(id);
			setJobName(jobName);
			setWage(wage.toString());
			setJobRestrictions(jobRestrictions);
			setJobDescription(jobDescription);
			setShifts(shiftsTemp);
			setStartDate(new Date(startDate));
			setIsDraft(isDraft);
			setIsContinousRole(isContinuousRole);
			setIsTrialShift(isTrialShift);
		}
	}, [state]);

	const save = ({ isDraft }: { isDraft: boolean }) => {
		const promise = createJobListing({
			variables: {
				id,
				jobName,
				wage: parseFloat(wage),
				jobRestrictions,
				jobDescription,
				shifts,
				startDate: startDate?.toISOString() as string,
				isDraft,
				isContinuousRole,
				isTrialShift,
			},
		});

		toast.promise(promise, {
			success: {
				title: `${isDraft ? "Saved!" : "Posted!"}`,
				position: "bottom-right",
				description: "Looks fantastic!",
				isClosable: true,
			},
			error: {
				title: `Failed to ${isDraft ? "save draft" : "post"}`,
				position: "bottom-right",
				description: "Please try again",
				isClosable: true,
			},
			loading: {
				title: `${isDraft ? "Saving..." : "Posting..."}`,
				position: "bottom-right",
				description: "Please wait",
			},
		});

		promise.then(() => {
			if (!isDraft) {
				navigate("/job-listings");
			}
		});
	};
	const format = (val: string) => `€` + val;
	const parse = (val: string) => val.replace(/^\$/, "");

	const [id, setId] = useState();
	const [wage, setWage] = useState("12.70");
	const [jobName, setJobName] = useState("");
	const [jobRestrictions, setJobRestrictions] = useState("");
	const [jobDescription, setJobDescription] = useState("");
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [isDraft, setIsDraft] = useState<boolean>(true);
	const [isTrialShift, setIsTrialShift] = useState<boolean>(true);
	const [isContinuousRole, setIsContinousRole] = useState<boolean>(true);

	const [shifts, setShifts] = useState<Shift[]>([
		{ start: "10:00", end: "20:00", days: [] },
	]);

	const totalTimeInShifts = shifts.reduce((acc, shift) => {
		if (shift.start && shift.end) {
			const duration = dayjs(shift.end, "HH:mm").diff(
				dayjs(shift.start, "HH:mm"),
				"minutes"
			);
			return acc + duration * shift.days.length;
		}
		return acc;
	}, 0);

	const totalTimeInHoursAndMinutes = `${Math.floor(totalTimeInShifts / 60)}h ${
		totalTimeInShifts % 60
	}m`;

	return (
		<PageContainer>
			<MenuBar />
			<BreadcrumContainer>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to="/dashboard">
							Dashboard
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to="/job-listings">
							Listings
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink>Create Listing</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</BreadcrumContainer>

			<Container>
				<BackButton />
				<SpacingContainer>
					<Heading>{isDraft ? "Create Listing" : "Update Listing"}</Heading>

					<Text>Tell us what you're looking for.</Text>
				</SpacingContainer>

				<Divider />
				<SpacingContainer>
					<Flex alignItems="center" justifyContent="space-between">
						<Heading size="lg" flex={1}>
							New Listing
						</Heading>
						<InputGroup width="auto">
							<Button
								marginRight="1rem"
								onClick={() => save({ isDraft: true })}
								isLoading={loading}
							>
								{isDraft ? "Save Draft" : "Remove post and save as draft"}
							</Button>
							<Button
								colorScheme="purple"
								onClick={() => save({ isDraft: false })}
								isLoading={loading}
								variant={isDraft ? "solid" : "outline"}
							>
								{isDraft ? "Post job posting" : "Update job posting"}
							</Button>
						</InputGroup>
					</Flex>
				</SpacingContainer>
				<SpacingContainer>
					<Stack spacing={10} marginBottom="1rem">
						<FormControl>
							<FormLabel>Job name</FormLabel>
							<InputGroup>
								<Input
									focusBorderColor="purple.500"
									type="text"
									onChange={(e) => setJobName(e.currentTarget.value)}
									value={jobName}
								/>
							</InputGroup>
						</FormControl>

						<FormControl>
							<FormLabel htmlFor="email">You pay </FormLabel>
							<InputGroup>
								<NumberInput
									min={12.7}
									onChange={(valueAsString: string) =>
										setWage(parse(valueAsString))
									}
									value={format(wage)}
									precision={2}
									step={0.5}
									focusBorderColor="purple.500"
								>
									<NumberInputField />
									<NumberInputStepper>
										<NumberIncrementStepper />
										<NumberDecrementStepper />
									</NumberInputStepper>
								</NumberInput>
								<Flex flexDirection="column" justifyContent="center">
									<Text ml={5} mb={0}>
										per hour
									</Text>
								</Flex>
							</InputGroup>
						</FormControl>

						<FormControl>
							<Stack justifyContent="space-between">
								<FormLabel>
									Job description - what will they be doing? what is your
									business like?
								</FormLabel>
							</Stack>
							<InputGroup>
								<Textarea
									focusBorderColor="purple.500"
									placeholder="You'll be doing..."
									onChange={(e) => setJobDescription(e.currentTarget.value)}
									value={jobDescription}
								/>
							</InputGroup>
						</FormControl>

						<FormControl>
							<Stack justifyContent="space-between">
								<FormLabel>
									Is this a job listing for continuous employment or is this a
									one-time job?
								</FormLabel>
							</Stack>
							<InputGroup>
								<CheckboxGroup>
									<Checkbox
										isChecked={isContinuousRole}
										onChange={() => {
											setIsContinousRole(!isContinuousRole);
										}}
									>
										This listing is for continuous employment
									</Checkbox>
								</CheckboxGroup>
							</InputGroup>
						</FormControl>

						{isContinuousRole && (
							<FormControl>
								<Stack justifyContent="space-between">
									<FormLabel>
										Does this role require the employee to pass a trial shift?
									</FormLabel>
								</Stack>
								<InputGroup>
									<CheckboxGroup>
										<Checkbox
											isChecked={isTrialShift}
											onChange={() => {
												setIsTrialShift(!isTrialShift);
											}}
										>
											A trial shift is required
										</Checkbox>
									</CheckboxGroup>
								</InputGroup>
							</FormControl>
						)}

						<FormControl>
							<Stack justifyContent="space-between">
								<FormLabel htmlFor="description">
									Job restrictions - what kind of people are you looking for?
								</FormLabel>
							</Stack>
							<InputGroup>
								<Textarea
									focusBorderColor="purple.500"
									placeholder="We're looking for..."
									onChange={(e) => setJobRestrictions(e.currentTarget.value)}
									value={jobRestrictions}
								/>
							</InputGroup>
						</FormControl>

						<FormControl>
							<Stack justifyContent="space-between">
								<FormLabel>On-site contact phone number</FormLabel>
							</Stack>
							<InputGroup>
								<Input
									focusBorderColor="purple.500"
									type="tel"
									placeholder="+353 (00) 0000 000"
								/>
							</InputGroup>
						</FormControl>

						<FormControl>
							<Stack justifyContent="space-between">
								<FormLabel htmlFor="description">
									When's the start date?
								</FormLabel>

								<DatePicker
									initialValue={startDate ? new Date(startDate) : new Date()}
									onDateChange={(date) => {
										if (date instanceof Date && !isNaN(date as any)) {
											setStartDate(date);
										}
									}}
								/>

								<Text>
									We recommend that the job start date should be at least 3
									weeks away to maximise candidate selction.
								</Text>
							</Stack>
						</FormControl>

						<Divider mt={10} />

						<Heading mt={10} size="md">
							Shifts
						</Heading>
						<Text>
							You can edit shifts during employment, but this is just to let
							them know what their first few week will look like.
						</Text>
						<ShiftCreator setShifts={setShifts} shifts={shifts} />
					</Stack>
				</SpacingContainer>

				<Card variant="outline">
					<CardHeader>
						<Heading>Selected shifts overview</Heading>
					</CardHeader>
					<CardBody>
						{shifts.map(
							(shift, i) =>
								shift.start &&
								shift.end &&
								shift.days.length > 0 && (
									<Flex key={i} gap={2}>
										<Text fontWeight="bold">
											{dayjs(shift.start, "HH:mm").format("HH:mm")}
											{" - "}
											{dayjs(shift.end, "HH:mm").format("HH:mm")}
										</Text>
										<Text>on {shift.days.join(", ")}</Text>
									</Flex>
								)
						)}
						{totalTimeInShifts > 0 && (
							<Text>Total time: {totalTimeInHoursAndMinutes}</Text>
						)}
					</CardBody>
				</Card>
				<Divider mt={10} />
				<Heading size="md" mt={10}>
					Save or Post
				</Heading>
				<SpacingContainer>
					<FormControl>
						<Stack justifyContent="space-between">
							<FormLabel htmlFor="description">
								When you're ready, click submit to send your listing for review.
							</FormLabel>
						</Stack>
						<InputGroup mt={3}>
							<Button
								marginRight="1rem"
								onClick={() => save({ isDraft: true })}
								isLoading={loading}
							>
								{isDraft ? "Save Draft" : "Remove post and save as draft"}
							</Button>
							<Button
								colorScheme="purple"
								onClick={() => save({ isDraft: false })}
								isLoading={loading}
								variant={isDraft ? "solid" : "outline"}
							>
								{isDraft ? "Post job posting" : "Update job posting"}
							</Button>
						</InputGroup>
					</FormControl>
				</SpacingContainer>
			</Container>
		</PageContainer>
	);
};

const BreadcrumContainer = styled.div`
	width: 100%;
	max-width: 1200px;
	display: flex;
	flex-direction: row;
	margin-left: -2rem;
`;
const Container = styled.div`
	width: 100%;
	max-width: 1200px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
`;

const SpacingContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 20px;
`;
