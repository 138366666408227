import { Button, Flex } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect } from "react";
import { NewShift } from "./atoms/NewShift";
import { Shift } from "../types";

type Props = {
	shifts: Shift[];
	setShifts: Dispatch<SetStateAction<Shift[]>>;
};

export const ShiftCreator = ({ shifts, setShifts }: Props) => {
	const removeShift = (shift: Shift) => {
		setShifts((prevShifts) => prevShifts.filter((s) => s !== shift));
	};

	const addShift = () => {
		setShifts((prevShifts) => [
			...prevShifts,
			{ start: "10:00", end: "20:00", days: [] },
		]);
	};

	const onShiftChange = (shift: Shift, key: number) => {
		const currShifts = [...shifts];
		currShifts[key] = shift;
		setShifts(currShifts);
	};

	return (
		<>
			{shifts.map((shift, i) => (
				<NewShift
					key={i}
					index={i}
					onClose={() => removeShift(shift)}
					onShiftChange={onShiftChange}
					shift={shift}
				/>
			))}
			<Flex>
				<Button onClick={addShift} flexShrink={0}>
					Add another shift
				</Button>
			</Flex>
		</>
	);
};
