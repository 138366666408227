import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Flex } from "@chakra-ui/react";

import { FaArrowLeft } from "react-icons/fa";

export const BackButton = () => {
	const navigate = useNavigate();
	return (
		<Flex>
			<Button
				onClick={() => {
					navigate(-1);
				}}
				variant={"ghost"}
			>
				<FaArrowLeft />
			</Button>
		</Flex>
	);
};
