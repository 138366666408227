import {
	Button,
	ButtonGroup,
	Card,
	CardHeader,
	CardBody,
	FormControl,
	FormLabel,
	Stack,
	Input,
	VStack,
	HStack,
	Heading,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Shift } from "../../types";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

type Props = {
	onClose: () => void;
	onShiftChange: (shift: Shift, key: number) => void;
	index: number;
	shift: Shift;
};

export const NewShift = ({ onClose, onShiftChange, index, shift }: Props) => {
	const [selectedTime, setSelectedTime] = useState<{
		start: string | null;
		end: string | null;
	}>({ start: shift.start, end: shift.end });

	const [selectedDays, setSelectedDays] = useState<string[]>(shift?.days || []);

	useEffect(() => {
		setSelectedTime({ start: shift.start, end: shift.end });
		setSelectedDays(shift.days || []);
	}, [shift]);

	const handleDayClick = (day: string) => {
		if (selectedDays.includes(day)) {
			setSelectedDays(selectedDays.filter((d) => d !== day));
		} else {
			setSelectedDays([...selectedDays, day]);
		}
	};

	useEffect(() => {
		if (selectedTime.start && selectedTime.end) {
			const shift = {
				start: selectedTime.start,
				end: selectedTime.end,
				days: selectedDays,
			};
			onShiftChange(shift, index);
		}
	}, [selectedTime.start, selectedTime.end, selectedDays]);

	return (
		<Card>
			<CardHeader display="flex" justifyContent="space-between">
				<Heading size="md">Shift #{index + 1}</Heading>
				<Button onClick={onClose} variant="ghost">
					×
				</Button>
			</CardHeader>
			<CardBody>
				<FormControl pb={10}>
					<HStack spacing={10}>
						<VStack>
							<FormLabel htmlFor="description">Shift start time</FormLabel>
							<Input
								size="md"
								type="time"
								value={selectedTime.start ?? "10:00"}
								onChange={(e) => {
									if (
										e.target.value &&
										dayjs(e.target.value, "HH:mm").isBefore(
											dayjs(selectedTime.end, "HH:mm")
										)
									) {
										setSelectedTime({ ...selectedTime, start: e.target.value });
									} else {
										alert("Start time must be before end time");
									}
								}}
							/>
						</VStack>

						<VStack>
							<FormLabel htmlFor="description">Shift end time</FormLabel>
							<Input
								size="md"
								type="time"
								value={selectedTime.end ?? "20:00"}
								onChange={(e) => {
									if (
										e.target.value &&
										dayjs(e.target.value, "HH:mm").isAfter(
											dayjs(selectedTime.start, "HH:mm")
										)
									) {
										setSelectedTime({ ...selectedTime, end: e.target.value });
									} else {
										alert("End time must be after start time");
									}
								}}
							/>
						</VStack>
					</HStack>
				</FormControl>

				<FormControl>
					<Stack justifyContent="space-between">
						<FormLabel htmlFor="description">On what days?</FormLabel>
					</Stack>

					<ButtonGroup>
						<Button
							colorScheme={selectedDays.includes("Monday") ? "purple" : "gray"}
							color={selectedDays.includes("Monday") ? "white" : undefined}
							onClick={() => handleDayClick("Monday")}
						>
							Monday
						</Button>
						<Button
							colorScheme={selectedDays.includes("Tuesday") ? "purple" : "gray"}
							color={selectedDays.includes("Tuesday") ? "white" : undefined}
							onClick={() => handleDayClick("Tuesday")}
						>
							Tuesday
						</Button>
						<Button
							colorScheme={
								selectedDays.includes("Wednesday") ? "purple" : "gray"
							}
							color={selectedDays.includes("Wednesday") ? "white" : undefined}
							onClick={() => handleDayClick("Wednesday")}
						>
							Wednesday
						</Button>
						<Button
							colorScheme={
								selectedDays.includes("Thursday") ? "purple" : "gray"
							}
							color={selectedDays.includes("Thursday") ? "white" : undefined}
							onClick={() => handleDayClick("Thursday")}
						>
							Thursday
						</Button>
						<Button
							colorScheme={selectedDays.includes("Friday") ? "purple" : "gray"}
							color={selectedDays.includes("Friday") ? "white" : undefined}
							onClick={() => handleDayClick("Friday")}
						>
							Friday
						</Button>
						<Button
							colorScheme={
								selectedDays.includes("Saturday") ? "purple" : "gray"
							}
							color={selectedDays.includes("Saturday") ? "white" : undefined}
							onClick={() => handleDayClick("Saturday")}
						>
							Saturday
						</Button>
						<Button
							colorScheme={selectedDays.includes("Sunday") ? "purple" : "gray"}
							color={selectedDays.includes("Sunday") ? "white" : undefined}
							onClick={() => handleDayClick("Sunday")}
						>
							Sunday
						</Button>
					</ButtonGroup>
				</FormControl>
			</CardBody>
		</Card>
	);
};
