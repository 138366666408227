import { useContext } from "react";
import {
	Avatar,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { AuthContext, AuthContextType } from "../../contexts/";
import { LogoutModal, NoCompanyAttachedAlert } from "./";
import styled from "styled-components";
import { gql, useQuery } from "@apollo/client";

const USER_QUERY_SELF = gql`
	query Query {
		selfBusinessUser {
			businessIds
		}
	}
`;

export const MenuBar = () => {
	const { currentUser, signOut } = useContext<AuthContextType>(AuthContext);
	const { loading, data: userData } = useQuery(USER_QUERY_SELF);
	const { businessIds } = userData?.selfBusinessUser || {};

	const navigate = useNavigate();

	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleSignOut = async () => {
		try {
			await signOut();
			navigate("/signin");
		} catch {}
	};

	return (
		<>
			<LogoutModal
				isOpen={isOpen}
				onClose={onClose}
				onSignOut={handleSignOut}
			/>
			{!loading && (
				<NoCompanyAttachedAlert isOpen={!businessIds} onClose={handleSignOut} />
			)}
			<Container>
				<Row>
					<Menu>
						<MenuButton>
							{loading ? (
								<></>
							) : (
								<Avatar bg="teal.500" name={currentUser?.email as string} />
							)}
						</MenuButton>

						<MenuList>
							<MenuItem
								onClick={() => {
									onOpen();
								}}
							>
								Log out
							</MenuItem>
						</MenuList>
					</Menu>
				</Row>
			</Container>
		</>
	);
};

const Container = styled.div`
	width: 100%;
	max-width: 1200px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
`;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-self: end;
	padding-top: 20px;
	padding-bottom: 20px;
`;
