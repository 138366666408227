import { useEffect, useState } from "react";
import {
	Heading,
	Text,
	Card,
	SimpleGrid,
	CardHeader,
	CardBody,
	CardFooter,
	Button,
	Divider,
	BreadcrumbItem,
	BreadcrumbLink,
	Breadcrumb,
	Badge,
	Flex,
	Skeleton,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BackButton, MenuBar, PageContainer } from "../components/atoms";
import { FaArrowRight, FaChevronRight } from "react-icons/fa";
import { day } from "../types";
import { gql, useQuery } from "@apollo/client";

type Job = {
	jobName: string;
	wage: number;
	jobRestrictions: string;
	jobDescription: string;
	shifts: Shift[];
	daysWorking: string[];
	startDate: Date;
	isDraft: boolean;
};

type Shift = {
	start: Date;
	end: Date;
	days: day[];
};

const JOB_LISTINGS_QUERY = gql`
	query ListJobs {
		selfBusinessActiveJobListings {
			id
			jobName
			wage
			jobRestrictions
			jobDescription
			shifts {
				start
				end
				days
			}
			startDate
			isDraft
			isTrialShift
			isContinuousRole
		}
	}
`;

const CardSkeleton = () => (
	<Skeleton>
		<Card>
			<CardHeader>
				<Heading />
			</CardHeader>
			<CardBody />
			<CardFooter>
				<Button>
					<FaChevronRight />
				</Button>
			</CardFooter>
		</Card>
	</Skeleton>
);

export const JobListings = () => {
	const { loading, data: jobListingsData } = useQuery(JOB_LISTINGS_QUERY);
	const [jobListings, setJobListings] = useState<Job[] | undefined>();

	useEffect(() => {
		setJobListings(jobListingsData?.selfBusinessActiveJobListings);
	}, [jobListingsData]);

	const getDaysWorkingFromShift = (shifts: Shift[]) => {
		const days = shifts.map((s: Shift) => s.days).flat();
		const sortedDays = Object.values(day).filter((d) => days.includes(d));
		return sortedDays;
	};

	const navigate = useNavigate();
	return (
		<PageContainer>
			<MenuBar />
			<BreadcrumContainer>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to="/dashboard">
							Dashboard
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink>Listings</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</BreadcrumContainer>

			<Container>
				<SpacingContainer>
					<Heading>Job Listings</Heading>
				</SpacingContainer>

				<SpacingContainer>
					<Link to="/create-listing">
						<Button display="flex" alignItems="center">
							<Heading size="md" marginRight="2" mb={0}>
								Create listing
							</Heading>
							<FaArrowRight />
						</Button>
					</Link>
				</SpacingContainer>
				<Divider />
				<SpacingContainer>
					<Heading size="lg">Listings</Heading>
				</SpacingContainer>
				<SpacingContainer>
					<SimpleGrid columns={[1, 1, 3]} spacing={10}>
						{loading ? (
							<>
								<CardSkeleton />
								<CardSkeleton />
								<CardSkeleton />
							</>
						) : jobListings?.length ?? 0 > 0 ? (
							jobListings?.map((job, i) => {
								const { jobName, isDraft, shifts } = job;
								return (
									<Card key={i}>
										<CardHeader display="flex" justifyContent="space-between">
											<Heading size="md">{jobName}</Heading>
											{isDraft && (
												<Badge colorScheme="purple" alignSelf="start">
													Draft
												</Badge>
											)}
										</CardHeader>
										<CardBody>
											<Flex gap={2}>
												{getDaysWorkingFromShift(shifts)?.map((day) => (
													<Badge key={day}>{day}</Badge>
												))}
											</Flex>
										</CardBody>
										<CardFooter>
											<Button
												onClick={() =>
													navigate("/create-listing", { state: job })
												}
											>
												<FaChevronRight />
											</Button>
										</CardFooter>
									</Card>
								);
							})
						) : (
							<Card
								variant="outline"
								borderStyle="dashed"
								borderWidth={2}
								borderColor="gray.600"
							>
								<CardHeader display="flex" justifyContent="space-between">
									<Heading size="md" color="gray.600">
										Create new listing
									</Heading>
								</CardHeader>
								<CardBody>
									<Text color="gray.600">
										It looks like you haven't created any job listings yet.
										Draft up a new listing!
									</Text>
								</CardBody>
								<CardFooter>
									<Button onClick={() => navigate("/create-listing")}>
										<FaChevronRight />
									</Button>
								</CardFooter>
							</Card>
						)}
					</SimpleGrid>
				</SpacingContainer>
			</Container>
		</PageContainer>
	);
};

const BreadcrumContainer = styled.div`
	width: 100%;
	max-width: 1200px;
	display: flex;
	flex-direction: row;
	margin-left: -2rem;
`;
const Container = styled.div`
	width: 100%;
	max-width: 1200px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
`;

const SpacingContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 20px;
`;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-self: end;
	padding-top: 20px;
	padding-bottom: 20px;
`;
