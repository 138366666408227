import React, { useState } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Text,
	Button,
	useDisclosure,
	Card,
	CardHeader,
	Box,
	CardBody,
	Heading,
	CardFooter,
	Badge,
	Flex,
	Stack,
	HStack,
	VStack,
} from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/fa";

type Props = {
	applicant: {
		id: string;
		fullName: string;
		profile: {
			color: string;
			emoji: string;
		};
		skills?: string[];
		videoUrl?: string;
		availableHoursPerWeek?: number;
		creationDate: any;
	};
};

export const ApplicantCard: React.FC<Props> = ({ applicant }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		fullName,
		profile,
		skills,
		videoUrl,
		availableHoursPerWeek,
		creationDate,
	} = applicant;
	const [viewVideo, setViewVideo] = useState(false);

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<Heading>{fullName}</Heading>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<VStack spacing={5}>
							<Box
								backgroundColor={applicant.profile.color}
								width={70}
								height={70}
								borderRadius={50}
								textAlign="center"
								justifyContent="center"
								alignItems="center"
								display="flex"
							>
								<Text fontSize={40} mb={0}>
									{profile.emoji}
								</Text>
							</Box>
							<Heading size="sm">{fullName}</Heading>

							<Stack direction="row">
								{applicant.skills?.map((skill: string) => (
									<Badge>{skill}</Badge>
								))}
							</Stack>
							{videoUrl && (
								<Button onClick={() => setViewVideo(!viewVideo)}>
									{viewVideo ? "Close video" : "View Video"}
								</Button>
							)}
							{videoUrl && viewVideo && (
								<video controls>
									<source src={videoUrl} type="video/mp4" />
								</video>
							)}

							{availableHoursPerWeek && (
								<Badge colorScheme="green">
									Available {availableHoursPerWeek} hrs / week
								</Badge>
							)}

							<HStack>
								<Badge colorScheme="green">✅ Email verified</Badge>
								<Badge colorScheme="green">✅ Phone verified</Badge>
							</HStack>

							{creationDate && (
								<Text>
									Account is{" "}
									<b>
										{Math.floor(
											(new Date().getTime() -
												new Date(creationDate._seconds * 1000).getTime()) /
												(1000 * 3600 * 24)
										)}{" "}
									</b>
									days old
								</Text>
							)}
						</VStack>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="blue" onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Card>
				<CardHeader display="flex" justifyContent="space-between">
					<Box
						backgroundColor={profile.color}
						width={70}
						height={70}
						borderRadius={50}
						textAlign="center"
						justifyContent="center"
						alignItems="center"
						display="flex"
					>
						<Text fontSize={40} mb={0}>
							{profile.emoji}
						</Text>
					</Box>
				</CardHeader>
				<CardBody>
					<Flex direction="column" gap={2}>
						<Heading size="sm">{fullName}</Heading>
						<Stack direction="row">
							{skills?.map((skill: string) => (
								<Badge>{skill}</Badge>
							))}
						</Stack>
					</Flex>
				</CardBody>
				<CardFooter>
					<Button
						onClick={() => {
							onOpen();
						}}
					>
						View Profile
						<FaChevronRight />
					</Button>
				</CardFooter>
			</Card>
		</>
	);
};
