import {
	Heading,
	Button,
	FormControl,
	FormLabel,
	Input,
	Container,
	Text,
	Stack,
	InputGroup,
	InputLeftElement,
	Icon,
	Box,
	Divider,
	useToast,
	Image,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { AuthContextType } from "../../contexts/AuthContextType";
import { FaRegEnvelope, FaLock } from "react-icons/fa";

export const NewPassword = () => {
	const emailRef = useRef<HTMLInputElement>(null);
	const passwordRef = useRef<HTMLInputElement>(null);
	const [, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const { newPassword, checkNewPasswordCode } =
		useContext<AuthContextType>(AuthContext);
	const navigate = useNavigate();
	const toast = useToast();

	// Get oobCode from URL parameter
	const searchParams = new URLSearchParams(window.location.search);
	const oobCode = searchParams.get("oobCode");

	useEffect(() => {
		const fetchData = async () => {
			const { data } = await checkNewPasswordCode(oobCode!);
			emailRef.current!.value = data?.email || "";
		};
		fetchData();
	}, [oobCode]);

	async function handleSubmit() {
		try {
			setError("");
			setLoading(true);
			if (oobCode) {
				await newPassword(passwordRef.current!.value, oobCode);
				toast({
					title: "Reset success.",
					status: "success",
					isClosable: true,
				});
				navigate("/dashboard");
			} else {
				throw new Error("Invalid oobCode");
			}
		} catch (error) {
			toast({
				title: "Reset failed.",
				status: "error",
				isClosable: true,
			});
			setError((error as Error).message);
		}

		setLoading(false);
	}

	return (
		<Container
			className="d-flex align-items-center justify-content-center"
			style={{ minHeight: "100vh" }}
		>
			<Box
				width={{ base: "90%", md: "400px" }}
				bg="secondary.card"
				rounded="lg"
				p={5}
			>
				<Box pb="2">
					<Image src="logo.png" height="40px" width="auto" />
				</Box>

				<Heading marginBottom="1.5rem">New password</Heading>

				<Stack spacing={4} marginBottom="1rem">
					<FormControl>
						<FormLabel htmlFor="email">Email Address</FormLabel>
						<InputGroup>
							<InputLeftElement
								children={
									<Icon as={FaRegEnvelope} color="secondary.inputHelper" />
								}
							/>
							<Input
								focusBorderColor="purple.500"
								type="email"
								name="email"
								id="email"
								ref={emailRef}
								placeholder="name@example.com"
								required
							/>
						</InputGroup>
					</FormControl>

					<FormControl>
						<Stack justifyContent="space-between" isInline>
							<FormLabel htmlFor="password">New password</FormLabel>
						</Stack>
						<InputGroup>
							<InputLeftElement
								children={<Icon as={FaLock} color="secondary.inputHelper" />}
							/>
							<Input
								focusBorderColor="purple.500"
								name="new-password"
								id="new-password"
								type="password"
								autoComplete="new-password"
								placeholder="Enter your new password"
								ref={passwordRef}
								required
							/>
						</InputGroup>
					</FormControl>
				</Stack>
				<Stack marginBottom="1rem">
					<Button
						type="submit"
						colorScheme="purple"
						isLoading={loading}
						onClick={handleSubmit}
					>
						Reset password
					</Button>
				</Stack>

				<Divider marginBottom="1rem" />
				<Stack>
					<Text textAlign="center" fontWeight="500">
						Already have any account?
						<ChakraLink
							color="purple.500"
							variant="outline"
							as={ReactRouterLink}
							to="/signin"
							pl="2"
						>
							Sign in
						</ChakraLink>
					</Text>
				</Stack>
			</Box>
		</Container>
	);
};
