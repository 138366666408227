import { useEffect, useState } from "react";
import {
	Heading,
	Text,
	Card,
	SimpleGrid,
	CardHeader,
	CardBody,
	CardFooter,
	Button,
	Divider,
	BreadcrumbItem,
	BreadcrumbLink,
	Breadcrumb,
	Badge,
	Flex,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MenuBar, PageContainer } from "../components/atoms";
import { day } from "../types";
import { gql, useQuery } from "@apollo/client";

// const ACTIVE_EMPLOYEES_QUERY = gql`
// 	query Query {
// 		selfBusinessActiveEmployees {
// 		}
// 	}
// `;

// const INBOUND_EMPLOYEES_QUERY = gql`
// 	query Query {
// 		selfBusinessFutureEmployees {
// 		}
// 	}
// `;

export const ManageEmployees = () => {
	// const { loading: loadingActive, data: jobListingsDataActive } = useQuery(
	// 	ACTIVE_EMPLOYEES_QUERY
	// );
	// const { loading: loadingInbound, data: jobListingsDataInbound } = useQuery(
	// 	INBOUND_EMPLOYEES_QUERY
	// );
	const navigate = useNavigate();
	return (
		<PageContainer>
			<MenuBar />
			<BreadcrumContainer>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to="/dashboard">
							Dashboard
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink>Manage employees</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</BreadcrumContainer>

			<Container>
				<SpacingContainer>
					<Heading>Rota view</Heading>
				</SpacingContainer>

				<Divider />
			</Container>

			<Container>
				<SpacingContainer>
					<Heading>Manage current employees</Heading>
				</SpacingContainer>

				<Divider />
				<SpacingContainer>
					<Heading size="lg">Current employees</Heading>
				</SpacingContainer>
				<SpacingContainer>
					<SimpleGrid columns={[1, 1, 3]} spacing={10}>
						<Text color="gray.700">🧐 You haven't got any employees.</Text>
					</SimpleGrid>
				</SpacingContainer>
			</Container>
		</PageContainer>
	);
};

const BreadcrumContainer = styled.div`
	width: 100%;
	max-width: 1200px;
	display: flex;
	flex-direction: row;
	margin-left: -2rem;
`;
const Container = styled.div`
	width: 100%;
	max-width: 1200px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
`;

const SpacingContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 20px;
`;
