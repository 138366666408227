import { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContextType, AuthContext } from "../contexts";

export const AdminEmulateView = () => {
	// Check if we have accessToken params in url
	// If we do, call the signIn function with the params
	const { signOut, signInWithAccessToken, currentUser } =
		useContext<AuthContextType>(AuthContext);
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();

	useEffect(() => {
		const accessToken = searchParams.get("accessToken");
		if (accessToken) {
			if (currentUser) {
				signOut().then(() =>
					signInWithAccessToken(accessToken).then(() => navigate("/dashboard"))
				);
			} else {
				signInWithAccessToken(accessToken).then(() => navigate("/dashboard"));
			}
		}
	}, [searchParams]);

	return <></>;
};
