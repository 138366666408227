import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from "@chakra-ui/react";
import React from "react";

export const NoCompanyAttachedAlert = ({
	isOpen,
	onClose,
}: {
	isOpen: boolean;
	onClose: () => void;
}) => {
	const cancelRef = React.useRef(null);

	return (
		<AlertDialog
			isOpen={isOpen}
			onClose={onClose}
			leastDestructiveRef={cancelRef}
			closeOnOverlayClick={false}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						You're not associated with any company at the moment.
					</AlertDialogHeader>

					<AlertDialogBody>
						You need to be associated with at least one company to access this
						page. Please contact your company's administrator or your Earny
						Account Manager.
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button onClick={onClose} ml={3}>
							Logout
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
