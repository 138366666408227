export type Job = {
	jobName: string;
	wage: number;
	jobRestrictions: string;
	jobDescription: string;
	shifts: [
		{
			start: Date;
			end: Date;
			days: day[];
		}
	];
	daysWorking: string[];
	startDate: Date;
	isDraft: boolean;
};

export enum day {
	Monday = "Monday",
	Tuesday = "Tuesday",
	Wednesday = "Wednesday",
	Thursday = "Thursday",
	Friday = "Friday",
	Saturday = "Saturday",
	Sunday = "Sunday",
}
