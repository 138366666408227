import {
	Heading,
	Text,
	SimpleGrid,
	Divider,
	BreadcrumbItem,
	BreadcrumbLink,
	Breadcrumb,
	Skeleton,
	Flex,
	Card,
	CardHeader,
	CardBody,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { MenuBar, PageContainer } from "../components/atoms";
import { gql, useQuery } from "@apollo/client";
import { ApplicantCard } from "../components";

const JOB_LISTINGS_QUERY = gql`
	query ListCandidates($listJobCandidatesId: String) {
		listJobCandidates(id: $listJobCandidatesId) {
			applicants {
				fullName
				profile {
					color
					emoji
				}
				skills
				videoUrl
				availableHoursPerWeek
				creationDate
			}
			topApplicants {
				fullName
				profile {
					color
					emoji
				}
				skills
				videoUrl
				availableHoursPerWeek
				creationDate
			}
		}
	}
`;

export const ViewJobApplicants = () => {
	const { state } = useLocation();
	const { jobName, id }: any = state;

	const { loading, data: jobListingsData } = useQuery(JOB_LISTINGS_QUERY, {
		variables: { listJobCandidatesId: id },
	});

	const { topApplicants, applicants } =
		jobListingsData?.listJobCandidates || {};

	return (
		<PageContainer>
			<MenuBar />
			<BreadcrumContainer>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to="/dashboard">
							Dashboard
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to="/view-applicants">
							View applicants
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink>{jobName} applicants</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</BreadcrumContainer>

			<Container>
				<SpacingContainer>
					<Heading>
						<i>{jobName}</i> applicants
					</Heading>
				</SpacingContainer>

				<Divider />

				<SpacingContainer>
					<Heading size="md">Our favourite applicants</Heading>
				</SpacingContainer>

				<SpacingContainer>
					{loading ? (
						<SimpleGrid columns={[1, 1, 3]} spacing={10}>
							<Skeleton height={200} />
							<Skeleton height={200} />
							<Skeleton height={200} />
						</SimpleGrid>
					) : topApplicants && topApplicants.length > 0 ? (
						<SimpleGrid columns={[1, 1, 3]} spacing={10}>
							{topApplicants.map((applicant: any) => (
								<ApplicantCard key={applicant.id} applicant={applicant} />
							))}
						</SimpleGrid>
					) : (
						<SimpleGrid columns={[1, 1, 3]} spacing={10}>
							<Card
								variant="outline"
								borderStyle="dashed"
								borderWidth={2}
								borderColor="gray.600"
								maxW={300}
							>
								<CardHeader display="flex" justifyContent="space-between">
									<Heading size="md" color="gray.600">
										Applicant 1
									</Heading>
								</CardHeader>
								<CardBody>
									<Text color="gray.600">
										It can take us a bit of time to pick our favourite
										applicants for you.
									</Text>
								</CardBody>
							</Card>
							<Card
								variant="outline"
								borderStyle="dashed"
								borderWidth={2}
								borderColor="gray.600"
								maxW={300}
							>
								<CardHeader display="flex" justifyContent="space-between">
									<Heading size="md" color="gray.600">
										Applicant 2
									</Heading>
								</CardHeader>
								<CardBody>
									<Text color="gray.600">
										It can take us a bit of time to pick our favourite
										applicants for you.
									</Text>
								</CardBody>
							</Card>
							<Card
								variant="outline"
								borderStyle="dashed"
								borderWidth={2}
								borderColor="gray.600"
								maxW={300}
							>
								<CardHeader display="flex" justifyContent="space-between">
									<Heading size="md" color="gray.600">
										Applicant 3
									</Heading>
								</CardHeader>
								<CardBody>
									<Text color="gray.600">
										It can take us a bit of time to pick our favourite
										applicants for you.
									</Text>
								</CardBody>
							</Card>
						</SimpleGrid>
					)}
				</SpacingContainer>

				<Divider />

				<SpacingContainer>
					<Heading size="md">All applicants</Heading>
				</SpacingContainer>

				{loading ? (
					<SimpleGrid columns={[1, 1, 3]} spacing={10}>
						<Skeleton height={200} />
						<Skeleton height={200} />
						<Skeleton height={200} />
					</SimpleGrid>
				) : applicants && applicants.length > 0 ? (
					<SimpleGrid columns={[1, 1, 3]} spacing={10}>
						{applicants.map((applicant: any) => (
							<ApplicantCard key={applicant.id} applicant={applicant} />
						))}
					</SimpleGrid>
				) : (
					<Text color="gray.700">No applicants for this job listing yet!</Text>
				)}
			</Container>
		</PageContainer>
	);
};

const BreadcrumContainer = styled.div`
	width: 100%;
	max-width: 1200px;
	display: flex;
	flex-direction: row;
	margin-left: -2rem;
`;

const Container = styled.div`
	width: 100%;
	max-width: 1200px;
	padding: 20px;
	margin-bottom: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
`;

const SpacingContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 20px;
`;
