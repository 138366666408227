import { useContext, useRef, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import {
	Box,
	Button,
	Container,
	Divider,
	FormControl,
	FormLabel,
	Heading,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	Stack,
	Text,
	Image,
	useToast,
} from "@chakra-ui/react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { FaLock, FaRegEnvelope, FaWarehouse } from "react-icons/fa";

export const SignUp = () => {
	const emailRef = useRef<HTMLInputElement>(null);
	const passwordRef = useRef<HTMLInputElement>(null);
	const passwordConfirmRef = useRef<HTMLInputElement>(null);
	const [, setError] = useState("");

	const { signUp } = useContext(AuthContext); // Define type for AuthContext

	const navigate = useNavigate();
	const toast = useToast();
	const [loading, setLoading] = useState(false);

	async function handleSubmit() {
		if (passwordRef.current?.value !== passwordConfirmRef.current?.value) {
			toast({
				title: "Signup failed.",
				description: "Passwords do not match.",
				status: "error",
				isClosable: true,
			});
			return setError("Passwords do not match");
		}

		try {
			setError("");
			setLoading(true);
			await signUp(
				emailRef.current?.value ?? "",
				passwordRef.current?.value ?? ""
			);
			navigate("/dashboard");
		} catch (error) {
			toast({
				title: "Signup failed.",
				description: "User already exists.",
				status: "error",
				isClosable: true,
			});
			setError((error as Error).message);
		}

		setLoading(false);
	}

	return (
		<Container
			className="d-flex align-items-center justify-content-center"
			style={{ minHeight: "100vh" }}
		>
			<Box
				width={{ base: "90%", md: "400px" }}
				bg="secondary.card"
				rounded="lg"
				p={5}
			>
				<Box pb="2">
					<Image src="logo.png" height="40px" width="auto" />
				</Box>

				<Heading marginBottom="1.5rem">Sign up</Heading>

				<Stack spacing={4} marginBottom="1rem">
					<FormControl>
						<FormLabel htmlFor="email">Email Address</FormLabel>
						<InputGroup>
							<InputLeftElement
								children={
									<Icon as={FaRegEnvelope} color="secondary.inputHelper" />
								}
							/>
							<Input
								focusBorderColor="purple.500"
								type="email"
								name="email"
								id="email"
								ref={emailRef}
								placeholder="name@example.com"
							/>
						</InputGroup>
					</FormControl>

					<FormControl>
						<Stack justifyContent="space-between" isInline>
							<FormLabel htmlFor="password">Password</FormLabel>
						</Stack>
						<InputGroup>
							<InputLeftElement
								children={<Icon as={FaLock} color="secondary.inputHelper" />}
							/>
							<Input
								focusBorderColor="purple.500"
								name="password"
								id="password"
								type="password"
								placeholder="Enter your password"
								ref={passwordRef}
							/>
						</InputGroup>
					</FormControl>
					<FormControl>
						<Stack justifyContent="space-between" isInline>
							<FormLabel htmlFor="password">Confirm password</FormLabel>
						</Stack>
						<InputGroup>
							<InputLeftElement
								children={<Icon as={FaLock} color="secondary.inputHelper" />}
							/>
							<Input
								focusBorderColor="purple.500"
								name="password"
								id="password"
								type="password"
								placeholder="Confirm your password"
								ref={passwordConfirmRef}
							/>
						</InputGroup>
					</FormControl>
				</Stack>
				<Stack marginBottom="1rem">
					<Button
						type="submit"
						colorScheme="purple"
						isLoading={loading}
						onClick={handleSubmit}
					>
						Sign up
					</Button>
				</Stack>

				<Divider marginBottom="1rem" />
				<Stack>
					<Text textAlign="center" fontWeight="500">
						Already have an account?
						<ChakraLink
							color="purple.500"
							variant="outline"
							as={ReactRouterLink}
							to="/signin"
							pl="2"
						>
							Sign in
						</ChakraLink>
					</Text>
				</Stack>
			</Box>
		</Container>
	);
};
