import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import {
	Heading,
	Text,
	StatGroup,
	Stat,
	StatLabel,
	StatNumber,
	StatHelpText,
	StatArrow,
	Card,
	SimpleGrid,
	CardHeader,
	CardBody,
	CardFooter,
	Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { AuthContextType } from "../contexts/AuthContextType";
import { MenuBar, PageContainer } from "../components/atoms";
import styled from "styled-components";
import { FaArrowRight } from "react-icons/fa";
import { gql, useQuery } from "@apollo/client";

const USER_QUERY_SELF = gql`
	query Query {
		selfBusinessUser {
			uid
			businessIds
			business {
				name
			}
			creationDate
		}
	}
`;

export const Dashboard = () => {
	const { currentUser } = useContext<AuthContextType>(AuthContext);
	const { data: userData } = useQuery(USER_QUERY_SELF);
	const { business } = userData?.selfBusinessUser || {};

	return (
		<PageContainer>
			<MenuBar />

			<Container>
				<SpacingContainer>
					<Heading>{business?.name} Dashboard</Heading>
					<Heading size="md">Welcome {currentUser?.email}</Heading>
				</SpacingContainer>

				<SpacingContainer>
					<StatGroup>
						<Stat>
							<StatLabel>Employees</StatLabel>
							<StatNumber>0</StatNumber>
							<StatHelpText>
								<StatArrow type="increase" />
								0.0%
							</StatHelpText>
						</Stat>

						<Stat>
							<StatLabel>Fill rate of earny slots</StatLabel>
							<StatNumber>100%</StatNumber>
							<StatHelpText>
								<StatArrow type="increase" />
								0%
							</StatHelpText>
						</Stat>
					</StatGroup>
				</SpacingContainer>
				<SpacingContainer>
					<SimpleGrid
						spacing={4}
						templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
					>
						<Link to="/view-applicants">
							<Card variant="filled">
								<CardHeader>
									<Heading size="md">View applicants</Heading>
								</CardHeader>
								<CardBody>
									<Text>
										Accept or decline applicants quickly. View their profiles
										and reach out to them.
									</Text>
								</CardBody>
								<CardFooter>
									<Button>
										<FaArrowRight />
									</Button>
								</CardFooter>
							</Card>
						</Link>
						<Link to="/job-listings">
							<Card variant="filled">
								<CardHeader>
									<Heading size="md">View job listings</Heading>
								</CardHeader>
								<CardBody>
									<Text>
										Create and modify job listings. Check out how they're doing.
									</Text>
								</CardBody>
								<CardFooter>
									<Button>
										<FaArrowRight />
									</Button>
								</CardFooter>
							</Card>
						</Link>

						<Link to="/manage-employees">
							<Card variant="filled">
								<CardHeader>
									<Heading size="md">Manage employees</Heading>
								</CardHeader>
								<CardBody>
									<Text>
										Modify rotas, schedule shifts and manage employees.
									</Text>
								</CardBody>
								<CardFooter>
									<Button>
										<FaArrowRight />
									</Button>
								</CardFooter>
							</Card>
						</Link>
					</SimpleGrid>
				</SpacingContainer>
			</Container>
		</PageContainer>
	);
};

const Container = styled.div`
	width: 100%;
	max-width: 1200px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
`;

const SpacingContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 20px;
`;
