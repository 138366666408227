import { useEffect, useState } from "react";
import {
	Heading,
	Text,
	Card,
	SimpleGrid,
	CardHeader,
	CardBody,
	CardFooter,
	Button,
	Divider,
	BreadcrumbItem,
	BreadcrumbLink,
	Breadcrumb,
	Badge,
	Flex,
	Skeleton,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MenuBar, PageContainer } from "../components/atoms";
import { FaChevronRight } from "react-icons/fa";
import { day } from "../types";
import { gql, useQuery } from "@apollo/client";

type Job = {
	jobName: string;
	wage: number;
	jobRestrictions: string;
	jobDescription: string;
	shifts: Shift[];
	daysWorking: string[];
	startDate: Date;
	isDraft: boolean;
};

type Shift = {
	start: Date;
	end: Date;
	days: day[];
};

const JOB_LISTINGS_QUERY = gql`
	query ListJobs {
		selfBusinessActiveJobListings {
			jobName
			shifts {
				start
				end
				days
			}
			startDate
			isDraft
			topApplicants
			applicants
			id
		}
	}
`;

export const ViewApplicants = () => {
	const { loading, data: jobListingsData } = useQuery(JOB_LISTINGS_QUERY);
	const [jobListings, setJobListings] = useState<Job[] | undefined>();

	useEffect(() => {
		setJobListings(jobListingsData?.selfBusinessActiveJobListings);
	}, [jobListingsData]);

	const getDaysWorkingFromShift = (shifts: Shift[]) => {
		const days = shifts.map((s: Shift) => s.days).flat();
		const sortedDays = Object.values(day).filter((d) => days.includes(d));
		return sortedDays;
	};

	const navigate = useNavigate();
	return (
		<PageContainer>
			<MenuBar />
			<BreadcrumContainer>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to="/dashboard">
							Dashboard
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink>View applicants</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</BreadcrumContainer>

			<Container>
				<SpacingContainer>
					<Heading>View applicants</Heading>
				</SpacingContainer>

				<Divider />
				<SpacingContainer>
					<Heading size="lg">Applicants by job listing</Heading>
				</SpacingContainer>
				<SpacingContainer>
					<SimpleGrid columns={[1, 1, 3]} spacing={10}>
						{loading ? (
							<>
								<CardSkeleton />
								<CardSkeleton />
								<CardSkeleton />
							</>
						) : jobListings?.filter((job) => !job.isDraft).length ?? 0 > 0 ? (
							jobListings
								?.filter((job) => !job.isDraft)
								.map((job, i) => {
									const { jobName, shifts } = job;
									return (
										<Card key={i}>
											<CardHeader display="flex" justifyContent="space-between">
												<Heading size="md">{jobName}</Heading>
											</CardHeader>
											<CardBody>
												<Flex gap={2}>
													{getDaysWorkingFromShift(shifts)?.map((day) => (
														<Badge key={day}>{day}</Badge>
													))}
												</Flex>
											</CardBody>
											<CardFooter>
												<Button
													onClick={() =>
														navigate(`/view-applicants/view`, {
															state: job,
														})
													}
												>
													<FaChevronRight />
												</Button>
											</CardFooter>
										</Card>
									);
								})
						) : (
							<Text color="gray.700">
								🧐 You haven't got any active job listings.
							</Text>
						)}
					</SimpleGrid>
				</SpacingContainer>
			</Container>
		</PageContainer>
	);
};

const BreadcrumContainer = styled.div`
	width: 100%;
	max-width: 1200px;
	display: flex;
	flex-direction: row;
	margin-left: -2rem;
`;
const Container = styled.div`
	width: 100%;
	max-width: 1200px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
`;

const SpacingContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 20px;
`;

const CardSkeleton = () => (
	<Skeleton>
		<Card>
			<CardHeader>
				<Heading />
			</CardHeader>
			<CardBody />
			<CardFooter>
				<Button>
					<FaChevronRight />
				</Button>
			</CardFooter>
		</Card>
	</Skeleton>
);
